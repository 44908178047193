import React from "react";
import { Container, Block, Title, Phone, AuxSocials, IconImage} from "./styles.js";
import FacebookIcon from "../../assets/facebook-icon.png";
import InstagramIcon from "../../assets/instagram.png";
import LinkedinIcon from "../../assets/linkedin-icon.png";
import TwitterIcon from "../../assets/social-icon.png";

const Rodape = () => {

  function redirectLink(link){
    window.location.href = link;
  }

  return (
    <Container id="vendas">
      <Block>
        <Title>Contato</Title>
        <Phone>(11)9.7223-6923</Phone>
        <AuxSocials>
          <IconImage src={TwitterIcon} />
          <IconImage src={LinkedinIcon} />
          <IconImage style={{cursor: 'pointer'}} onClick={() => redirectLink('https://instagram.com/original_publicidade?igshid=YmMyMTA2M2Y=')} src={InstagramIcon} />
          <IconImage src={FacebookIcon} />
        </AuxSocials>
      </Block>
    </Container>
  );
};

export default Rodape;
