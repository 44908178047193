import { createSlice } from '@reduxjs/toolkit';

export const homeSlice = createSlice({
    name: 'home',
    initialState: {
        home: [],
        isLoading: false,
    },
    reducers: {
        getHomeFetch: (state) => {
            state.isLoading = true;
        },
        getHomeSuccess: (state, action) => {
            state.home = action.payload;
            state.isLoading = false;
        },
        getHomeFailure: (state) => {
            state.isLoading = false;
        }
    }
})

export const { getHomeFetch, getHomeSuccess, getHomeFailure} = homeSlice.actions;

export default homeSlice.reducer;