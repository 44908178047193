import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { HeaderContainer, Indent, ItemMenu, IndentItemsMenu, LogoImage, BannerImage, AuxItemMenu, AguaVivaImage, BorderGreenImage, FixedWhatsapp } from "./styles.js";
import Logo from "../../assets/logo.png";

import Whatsapp from "../../assets/whatsapp.svg";
 

const Header = () => {

  return (
    <HeaderContainer id="header">
      <Indent>
        <a href="/">
          <LogoImage src={Logo} />
        </a>

        <IndentItemsMenu>
          <AuxItemMenu>
            <AnchorLink className="no-underline" href="/"><ItemMenu>Home</ItemMenu></AnchorLink>
          </AuxItemMenu>
          <AuxItemMenu>
            <AnchorLink className="no-underline" href="#solucoes"><ItemMenu>Soluções</ItemMenu></AnchorLink>
          </AuxItemMenu>
          <AuxItemMenu>
            <AnchorLink className="no-underline" href="#ads"><ItemMenu>Agência</ItemMenu></AnchorLink>
          </AuxItemMenu>
          <AuxItemMenu>
            <AnchorLink className="no-underline" href="#contact"><ItemMenu>Contato</ItemMenu></AnchorLink>
          </AuxItemMenu>
        </IndentItemsMenu>
      </Indent>
      {/* <a href="https://api.whatsapp.com/send?phone=5511972236923" target="_blank">
        <FixedWhatsapp src={Whatsapp} />
      </a> */}
    </HeaderContainer>
  );
};

export default Header;
