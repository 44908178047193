import React from "react";
import { Container, Block, Title, Description, Button, ButtonMobile, DescriptionMobile  } from "./styles.js";

const AumentarVendas = () => {
  return (
    <Container id="solucoes">
      <Block>
        <Title>
          sites personalizados para 
          impulsionar suas vendas online. 
        </Title>
          <Description>
            Conte com nossa < br />
            expertise para criar um site < br />
            responsivo, eficiente e < br />
            visualmente objetivo, que < br />
            represente sua identidade e ofereça< br /> suporte e conteúdo eficaz.
          </Description>
        <Button href="https://api.whatsapp.com/send?phone=5511972236923">Contrate já</Button>
      </Block>
      <ButtonMobile href="https://api.whatsapp.com/send?phone=5511972236923">Contrate já</ButtonMobile>
          <DescriptionMobile>
            Conte com nossa < br />
            expertise para criar um site < br />
            responsivo, eficiente e < br />
            visualmente objetivo, que < br />
            represente sua identidade e ofereça< br /> suporte e conteúdo eficaz.
          </DescriptionMobile>
    </Container>
  );
};

export default AumentarVendas;
