import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import homeReducer from './homeState';
import homeSaga from './homeSaga';
;
const saga = createSagaMiddleware();
const store = configureStore({
  reducer: {
    home: homeReducer
  },
  middleware: [saga]
});
saga.run(homeSaga);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
