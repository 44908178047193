import styled from "styled-components";
import colors from "../../styles/colors";

export const SocialsContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  padding: 0 0 30px 0;
  background: ${colors.secondaryColor};

  .social-class-active{
    transition: all 0.2s ease-out;
    filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(123deg) brightness(103%) contrast(101%);
  }

  .social-class{
    transition: all 0.2s ease-out;
  }

  @media (max-width: 615px) {

  }
`;

export const Indent = styled.div`
  width: 100%;
  max-width: 1040px;


  @media (max-width: 615px) {

  }
`;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  font-family: 'Century Gothic', sans-serif;
  margin: 30px 0 60px 0;
  text-align: center;
  text-decoration: none;
  color: ${colors.primaryColor};
  text-underline-offset: 0.1em;


  @media (max-width: 615px) {
    width: 90%;
    text-align: center;
    margin: 20px auto;
    font-size: 24px;
  }
`;

export const LogoImage = styled.img`
  border-radius: 0 0 7px 7px;
  background: ${colors.primaryColor};
  margin: 0 auto;
  display: flex;


  @media (max-width: 615px) {
    width: 100%;

  }
`;

export const GreenName = styled.span`
  color: ${colors.whiteColor};

  @media (max-width: 615px) {

  }
`;

export const AuxSocials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 615px) {
    flex-direction: column;

  }
`;

export const SocialsItem = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 16px;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 615px) {
    margin: 20px 0;
  }
`;

export const SocialsImage = styled.img`
  width: 40px;
  filter: invert(9%) sepia(1%) saturate(2841%) hue-rotate(215deg) brightness(105%) contrast(91%);

  @media (max-width: 615px) {

  }
`;

export const SocialsName = styled.p`
  margin: 0 0 0 8px;
  font-family: 'Century Gothic', sans-serif;
  font-weight: 700;
  font-size: 22px;
  color: ${colors.whiteColor};

  @media (max-width: 615px) {

  }
`;