import styled from "styled-components";
import banner2 from "../../assets/banner2.png";
import bannerMobile2 from "../../assets/banner-mobile2.png";
import colors from "../../styles/colors";

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  background: url('${banner2}')no-repeat  #564077;
  height: 43.6vw;
  background-size: contain;
  font-family: 'Montserrat', sans-serif;
  position: relative;

  @media (max-width: 615px) {
    background: url('${bannerMobile2}')no-repeat;
    height: 998px;
  }
`;

export const Block = styled.div`
  width: 700px;
  position: absolute;
  left: 6%;
  top: 14%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 615px) {
    top: 10%;
    width: 80%;
    left: 15%;
    right: inherit;
    position: absolute;
  }
`;

export const Title = styled.h1`
  font-weight: 900;
  font-size: 2.5vw;
  color: ${colors.whiteColor};
  text-align: left;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 615px) {
    font-size: 25px;
    text-align: center;
  }
`;

export const Description = styled.p`
  font-weight: 500;
  font-size: 2vw;
  color: ${colors.whiteColor};
  text-align: left;
  margin: 30px 0 0 0;

  @media (max-width: 615px) {
   display: none;
  }
`;

export const DescriptionMobile = styled.p`
  display: none;

  @media (max-width: 615px) {
    font-weight: 500;
    font-size: 16px;
    color: ${colors.whiteColor};
    text-align: right;
    margin: 30px 0 0 0;
    position: absolute;
    bottom: 15%;
    display: block;
  }
`;

export const Button = styled.a`
  text-transform: uppercase;
  width: 20vw;
  height: 5vw;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.pink};
  border: 1px solid ${colors.whiteColor};
  box-shadow: 0 0px 28px ${colors.pink};
  margin: 30px 0 0 0;
  font-size: 2.3vw;
  color: ${colors.black};
  font-weight: 600;
  text-align: center;
  text-decoration: none;

  @media (max-width: 615px) {
   display: none;
  }
`;

export const ButtonMobile = styled.a`
  display: none;

  @media (max-width: 615px) {
    text-transform: uppercase;
    width: 180px;
    height: 40px;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.pink};
    border: 1px solid ${colors.whiteColor};
    box-shadow: 0 0px 28px ${colors.pink};
    margin: 30px 0 0 0;
    font-size: 18px;
    color: ${colors.black};
    font-weight: 600;
    text-align: center;
    position: absolute;
    bottom: 8%;
    right: 20%;
    text-decoration: none;
  }
`;