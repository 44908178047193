import React, { useState } from "react";
import emailjs from "emailjs-com";
import {
  ContactContainer,
  Title,
  Description,
  Indent,
  Input,
  InputArea,
  Button,
  TextButton,
  Loading,
  AuxLoading,
  Modal,
  TextModal,
  ButtonModal,
} from "./styles.js";
import LoadingGif from "../../assets/loading.gif";

function Contact() {
  const [loading, setLoading] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalError, setModalError] = useState(false);

  const userID = process.env.REACT_APP_USER_ID;
  const templateID = process.env.REACT_APP_TEMPLATE_ID;
  const serviceID = process.env.REACT_APP_SERVICE_ID;

  function sendEmail(e) {
    e.preventDefault();
    setLoading(true);

    emailjs.sendForm(serviceID, templateID, e.target, userID).then(
      (result) => {
        console.log(result.text);
        setLoading(false);
        e.target.reset();
        setModalSuccess(true);
      },
      (error) => {
        console.log(error.text);
        setLoading(false);
        setModalError(true);
      }
    );
  }

  function closeModal() {
    setModalError(false);
    setModalSuccess(false);
  }

  return (
    <ContactContainer id="contact">
      <Indent>
      <Title>Contato</Title>
      <Description>Entre em contato conosco e deixe sua mensagem!</Description>
        <form className="form" onSubmit={sendEmail}>
         
          <Input
            type="text"
            name="name"
            placeholder="NOME"
            required
          />
          
          <Input type="text" name="phone" placeholder="TELEFONE" required />
         
          <Input type="email" name="email" placeholder="EMAIL" required />

          <InputArea name="message" placeholder="MENSAGEM" />

          <AuxLoading>
            {loading === false ? (
                <Button type="submit" value="enviar">
                  <TextButton>ENVIAR</TextButton>
                </Button>
              ) : (
                <Loading>
                  <img src={LoadingGif} alt="metalso" />
                </Loading>
            )}
          </AuxLoading>
        </form>

        {modalSuccess && (
          <Modal>
            <TextModal>Mensagem enviada com sucesso!</TextModal>
            <ButtonModal onClick={closeModal}>
              <TextButton>Ok</TextButton>
            </ButtonModal>
          </Modal>
        )}

        {modalError && (
          <Modal>
            <TextModal>
              Não foi possível enviar sua mensagem, tente novamente mais tarde!
            </TextModal>
            <ButtonModal onClick={closeModal}>
              <TextButton>Ok</TextButton>
            </ButtonModal>
          </Modal>
        )}
      </Indent>
    </ContactContainer>
  );
}
export default Contact;
