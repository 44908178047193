import styled from "styled-components";
import rodape from "../../assets/rodape.png";
import colors from "../../styles/colors";

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  background: url('${rodape}')no-repeat  #000006;
  height: 23.6vw;
  background-size: contain;
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 615px) {
    height: 457px;
    background-size: inherit;
  }
`;

export const Block = styled.div`
  width: 300px;
  position: absolute;
  left: 10%;
  top: 35%;
  color: ${colors.whiteColor};

  @media (max-width: 615px) {
    width: 80%;
    left: 10%;
    right: inherit;
    position: absolute;
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 30px;
  font-style: italic;
  font-weight: 700;

  @media (max-width: 615px) {
   
  }
`;

export const Phone = styled.p`
  margin: 0;
  font-size: 30px;
  font-style: italic;
  font-weight: 700;

  @media (max-width: 615px) {
   
  }
`;

export const AuxSocials = styled.div`
  margin: 20px 0 0 0;

  @media (max-width: 615px) {
   
  }
`;

export const IconImage = styled.img`
  width: 40px;
  height: 40px;
  margin: 0 18px 0 0;

  @media (max-width: 615px) {
   
  }
`;