export const colors = {
  primaryColor: "#212023",
  secondaryColor: "#6dbe48",
  whiteColor: "#ffffff",
  black: '#000',
  green: '#1efd00',
  pink: '#ff58da',
  darkBlue: '#23238E',
  darkPink: '#ec0482'
};

export default colors;
