import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import Home from "./App";

function RoutesPath() {
  return (
    <Router>
      <Routes>
        <Route path="/" component={Home} />
      </Routes>
    </Router>
  );
}

export default RoutesPath;
