import React from "react";
import { Container, Block, Title, Description, Button, Subtitle, ButtonMobile } from "./styles.js";

const AumentarVendas = () => {
  return (
    <Container id="sites">
      <Block>
        <Title>
          GESTÃO DE < br/>
          <span style={{color : '#ec0482', fontStyle: 'italic'}}>MÍDIAS SOCIAS</span>
        </Title>
        <Subtitle>
          Fortaleça sua < br/>
          presença online! 
        </Subtitle>
        <Description>
          Planejamos postagens, produzimos< br/> conteúdos relevantes, personalizamos< br/> suas páginas e aumentamos o < br/>
          engajamento em suas redes sociais.< br/> Acompanhe a evolução com nossos< br/> relatórios de desempenho.
        </Description>
        <Button href="https://api.whatsapp.com/send?phone=5511972236923">Fale conosco</Button>
      </Block>
      <ButtonMobile href="https://api.whatsapp.com/send?phone=5511972236923">Fale conosco</ButtonMobile>
    </Container>
  );
};

export default AumentarVendas;
