import styled from "styled-components";
import colors from "../../styles/colors";

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #000;
  padding: 10px 0 0 0;

  .no-underline {
    text-decoration: none;
  }

  @media (max-width: 615px) {

  }
`;

export const Indent = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 615px) {

  }
`;

export const IndentItemsMenu = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 0;


  @media (max-width: 615px) {
    display: none;
  }
`;

export const ItemMenu = styled.p`
  color: ${colors.whiteColor};
  text-decoration: none!important;
  font-family: 'Montserrat', sans-serif;
  transition: all 0.1s;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 25px;
  margin: 0 20px 0 0;

  :hover {
    font-weight: 700;
    transition: all 0.1s;
  }


  @media (max-width: 615px) {

  }
`;

export const AuxItemMenu = styled.div`
  width: 125px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 615px) {

  }
`;

export const LogoImage = styled.img`
  width: 250px;

  @media (max-width: 615px) {
    width: 35vw;

  }
`;

export const BannerImage = styled.img`
  width:100%;


  @media (max-width: 615px) {

  }
`;

export const AguaVivaImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 10%;
  z-index: 2;
  width: 28vw;

  @media (max-width: 615px) {

  }
`;

export const BorderGreenImage = styled.img`
  width: 100%;
  background: ${colors.secondaryColor};
  position: absolute;
  bottom: 0;
  z-index: 1;


  @media (max-width: 615px) {

  }
`;

export const FixedWhatsapp = styled.img`
  position: fixed;
  width: 64px;
  height: 64px;
  right: 1%;
  bottom: 1%;
  z-index: 10;
  cursor: pointer;

  @media (max-width: 615px) {
    width: 32px;
    height: 32px;
  }
`;



