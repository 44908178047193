import styled from "styled-components";
import colors from "../../styles/colors";

export const JobsContainer = styled.section`
  width: 100%;
  margin: 60px auto 0 auto;
  padding: 20px 0 0 0;
  background: ${colors.primaryColor};

  .card-class-active {
    transition: all 0.3s ease-out;
    transform: translateY(0);
    height: auto;
    background: ${colors.whiteColor};
    padding: 5px;
    z-index: 1;
    width: 87%;
    margin: 0 auto;
    border-radius: 7px 7px 0 0;
  }

  .card-class {
    background: ${colors.whiteColor};
    transform: translateY(200%);
    padding: 5px;
    z-index: -1;
    transition: all 0.3s ease-out;
    border-radius: 7px 7px 0 0;
    width: 87%;
    margin: 0 auto;
  }

  @media (max-width: 615px) {

  }
`;

export const Indent = styled.div`
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;


  @media (max-width: 615px) {

  }
`;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  font-family: 'Century Gothic', sans-serif;
  margin: 0 0 60px 0;
  text-align: center;
  color: ${colors.whiteColor};


  @media (max-width: 615px) {

  }
`;

export const AuxJobs = styled.div`
  display: flex;
  justify-content: space-between;
  algin-items: flex-start;  


  @media (max-width: 615px) {
    flex-direction: column;
  }
`;

export const AuxImage = styled.a`
  width: 100%;
  overflow: hidden;
  margin: 0;
  
  @media (max-width: 615px) {
    text-align: center;
  }
`;

export const ImageJob = styled.img`
  width: 100%;
  height: 270px;
  filter: grayscale(100%);
  transition: all 0.3s ease-out;
  transform: scale(1);

  :hover{
    filter: grayscale(0);
    transition: all 0.3s ease-out;
    transform: scale(0.9);
    box-shadow: 0px 1px 10px 0px ${colors.primaryColor};
    cursor: pointer;
    border-radius: 7px;
  }
  

  @media (max-width: 615px) {
    width: 100%;
    margin: 0 auto;
    filter: none;
    transform: none;
    height: auto;
  }
`;

export const CardText = styled.div`  

  @media (max-width: 615px) {

  }
`;

export const TitleImage = styled.h2`
  color: ${colors.primaryColor};
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  font-family: 'Century Gothic', sans-serif;

  @media (max-width: 615px) {

  }
`;