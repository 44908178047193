import styled from "styled-components";
import banner1 from "../../assets/banner1.png";
import bannerMobile1 from "../../assets/banner-mobile1.png";
import colors from "../../styles/colors";

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  background: url('${banner1}')no-repeat #564077;
  height: 40.9vw;
  background-size: contain;
  font-family: 'Montserrat', sans-serif;
  position: relative;

  @media (max-width: 615px) {
    background: url('${bannerMobile1}')no-repeat;
    height: 992px;
  }
`;

export const Block = styled.div`
  width: 700px;
  position: absolute;
  right: 15%;
  top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  @media (max-width: 615px) {
    width: 80%;
    left: 10%;
    right: inherit;
    position: absolute;
  }
`;

export const Title = styled.h1`
  font-weight: 900;
  font-size: 4vw;
  color: ${colors.whiteColor};
  text-align: right;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 615px) {
    font-size: 35px;
  }
`;

export const Description = styled.p`
  font-weight: 500;
  font-size: 2vw;
  color: ${colors.whiteColor};
  text-align: right;
  text-transform: uppercase;
  margin: 30px 0 0 0;

  @media (max-width: 615px) {
    font-size: 20px;
  }
`;

export const Button = styled.a`
  text-transform: uppercase;
  width: 26vw;
  height: 7vw;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.green};
  border: 1px solid ${colors.whiteColor};
  box-shadow: 0 0px 28px ${colors.green};
  margin: 30px 0 0 0;
  font-size: 2.5vw;
  color: ${colors.black};
  font-weight: 600;
  text-align: right;
  text-decoration: none;

  @media (max-width: 615px) {
    display: none;
  }
`;

export const ButtonMobile = styled.a`
  display: none;

  @media (max-width: 615px) {
    text-transform: uppercase;
    width: 200px;
    height: 40px;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.green};
    border: 1px solid ${colors.whiteColor};
    box-shadow: 0 0px 28px ${colors.green};
    margin: 30px 0 0 0;
    font-size: 16px;
    color: ${colors.black};
    font-weight: 600;
    text-align: right;
    position: absolute;
    bottom: 10%;
    text-decoration: none;
  }
`;