import styled from "styled-components";
import colors from "../../styles/colors";

export const OurHistoryContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 60px auto;

  @media (max-width: 615px) {

  }
`;

export const Indent = styled.div`
  width: 100%;
  max-width: 1040px;


  @media (max-width: 615px) {
    width: 90%;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  font-family: 'Century Gothic', sans-serif;
  margin: 0 0 60px 0;
  text-align: center;
  text-decoration: underline;
  color: ${colors.secondaryColor};
  text-underline-offset: 0.1em;


  @media (max-width: 615px) {

  }
`;

export const Description = styled.p`
  font-size: 18px;
  font-weight: 500;
  font-family: 'Century Gothic', sans-serif;
  line-height: 40px;
  color: ${colors.primaryColor};
  text-align: left;
  margin: 0;
  padding: 0;
  width: 45%;


  @media (max-width: 615px) {
    width: 90%;
    text-align: center;
    margin: 20px 0 0 0;
  }
`;

export const ImagemOurHistory = styled.img`
  width: 50%;
  border-radius: 7px;


  @media (max-width: 615px) {
    width: 90%;
  }
`;

export const AuxOurHistory = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;


  @media (max-width: 615px) {
    flex-direction: column;

  }
`;

