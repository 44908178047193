import styled from "styled-components";
import banner4 from "../../assets/banner4.png";
import bannerMobile4 from "../../assets/banner-mobile4.png";
import colors from "../../styles/colors";

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  background: url('${banner4}')no-repeat #000006;
  height: 43.6vw;
  background-size: contain;

  @media (max-width: 615px) {
    background: url('${bannerMobile4}')no-repeat;
    height: 838px;
  }
`;

export const Block = styled.div`
  width: 700px;
  position: absolute;
  left: 6%;
  top: 7%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 615px) {
    width: 80%;
    left: 15%;
    right: inherit;
    position: absolute;
  }
`;

export const Title = styled.h1`
  font-weight: 900;
  font-size: 4vw;
  color: ${colors.whiteColor};
  text-align: left;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 615px) {
    font-size: 35px;
  }
`;

export const Subtitle = styled.h2`
  font-weight: 900;
  font-size: 2vw;
  color: ${colors.whiteColor};
  text-align: left;
  text-transform: uppercase;
  margin: 30px 0 0 0;

  @media (max-width: 615px) {
    font-size: 20px;
  }
`;

export const Description = styled.p`
  font-weight: 500;
  font-size: 2vw;
  color: ${colors.whiteColor};
  text-align: left;
  margin: 0;

  @media (max-width: 615px) {
    font-size: 16px;
  }
`;

export const Button = styled.a`
  text-transform: uppercase;
  width: 19vw;
  height: 4vw;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.darkPink};
  border: 1px solid ${colors.whiteColor};
  box-shadow: 0 0px 28px ${colors.darkBlue};
  margin: 30px 0 0 0;
  font-size: 2.2vw;
  color: ${colors.black};
  font-weight: 600;
  text-align: center;
  font-style: italic;
  text-decoration: none;

  @media (max-width: 615px) {
    display: none;
  }
`;

export const ButtonMobile = styled.a`
  display: none;

  @media (max-width: 615px) {
    text-transform: uppercase;
    width: 200px;
    height: 40px;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.darkPink};
    border: 1px solid ${colors.whiteColor};
    box-shadow: 0 0px 28px ${colors.darkBlue};
    margin: 30px 0 0 0;
    font-size: 18px;
    color: ${colors.black};
    font-weight: 600;
    text-align: center;
    font-style: italic;
    display: flex;
    position: absolute;
    bottom: 8%;
    text-decoration: none;
  }
`;

