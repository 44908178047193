import { call, put, takeEvery } from 'redux-saga/effects';
import { getHomeSuccess } from './homeState';

function* workGetHomeFetch(){
    const home = yield call(() => fetch('http://localhost:1337/api/home'));
    const formattedHome = yield home.json();
    yield put(getHomeSuccess(formattedHome));
}

function* homeSaga(){
    yield takeEvery('home/getHomeFetch', workGetHomeFetch);
}

export default homeSaga;