import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHomeFetch } from './homeState';

import RoutesPath from "./routesPath";
import Header from "./components/Header";
import OqueFazemos from "./components/OqueFazemos";
import AumentarVendas from "./components/AumentarVendas";
import SitesPersonalizados from "./components/SitesPersonalizados";
import Ads from "./components/Ads";
import MidiasSociais from "./components/MidiasSociais";
import Rodape from "./components/Rodape";
import Jobs from "./components/Jobs";
import Socials from "./components/Socials";
import Contact from "./components/Contact";
import OurHistory from "./components/OurHistory";
import Footer from "./components/Footer";

export default function App() {
  const home = useSelector(state => state.home)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomeFetch())
  }, [dispatch]);

  return (
    <>
      <head>
        <title>Original Publicidade</title>
        <meta name="description" content="Original Publicidade" />
        <link rel="icon" href="/originalpublicidade.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
      </head>
      <Header />
      {/* <OqueFazemos /> */}
      <AumentarVendas />
      <SitesPersonalizados />
      <Ads />
      <MidiasSociais />
      {/* <Jobs /> */}
      {/* <Socials /> */}
      {/* <OurHistory /> */}
      <Contact />
      <Rodape />
      {/* <Footer /> */}
      <RoutesPath />
    </>
  );
}
