import styled from "styled-components";
import colors from "../../styles/colors";

export const FooterContainer = styled.section`
  width: 100%;
  height: 330px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  background: ${colors.secondaryColor};

  @media (max-width: 615px) {

  }
`;

export const Indent = styled.div`
  width: 100%;
  max-width: 1040px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 615px) {

  }
`;

export const AuxFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 40px auto 0;

  @media (max-width: 615px) {

  }
`;

export const LogoImage = styled.img`
  width: 200px;
  border-radius: 0 0 7px 7px;
  background: ${colors.primaryColor};
  margin: 0 auto;
  display: flex;


  @media (max-width: 615px) {

  }
`;

export const ImageArrow = styled.img`
  width: 60px;
  height: 40px;
  transition: all 0.4s ease-out;
  transform: translateY(0);
  cursor: pointer;

  :hover{
    transform: translateY(-40%);
    transition: all 0.4s ease-out;
  }

  @media (max-width: 615px) {

  }
`;

export const DescriptionFooter = styled.p`
  color: ${colors.primaryColor};
  text-decoration: none;
  font-family: 'Century Gothic', sans-serif;
  transition: all 0.1s;
  font-weight: 500;

  @media (max-width: 615px) {
    font-size: 14px;

  }
`;


export const Socials = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 615px) {

  }
`;

export const LinkSocial = styled.a`
  text-decoration: none;
  margin: 30px 10px 10px 0;

  @media (max-width: 615px) {

  }
`;

export const IconsSocial = styled.img`
  width: 24px;
  height: 24px;
  transition: all 0.2s ease-out;

  :hover{
    transition: all 0.2s ease-out;
    filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(123deg) brightness(103%) contrast(101%);
  }

  @media (max-width: 615px) {

  }
`;