import React from "react";
import { Container, Block, Title, Description, Button, ButtonMobile } from "./styles.js";

const AumentarVendas = () => {
  return (
    <Container id="/">
      <Block>
        <Title>
          Quer aumentar
          suas vendas,
        </Title>
        <Description>
          capturar leads < br />
          qualificados e obter< br />
          resultados reais com< br />
          suas redes?
        </Description>
        <Button href="https://api.whatsapp.com/send?phone=5511972236923">Nós podemos< br /> ajudá-lo!</Button>
      </Block>
      <ButtonMobile href="https://api.whatsapp.com/send?phone=5511972236923">Nós podemos< br /> ajudá-lo!</ButtonMobile>
    </Container>
  );
};

export default AumentarVendas;
