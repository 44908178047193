import styled from "styled-components";
import colors from "../../styles/colors";

export const ContactContainer = styled.div`
  width: 100%;
  padding: 0 0 50px 0;
  margin: 0auto 100px auto;
  background: ${colors.black};
  position: relative;
`;

export const Title = styled.h2`
  color: ${colors.whiteColor};
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  margin: 0 auto 0 auto;
  font-family: 'Century Gothic', sans-serif;
  text-decoration: underline;

  @media screen and (max-width: 900px) {

  }
`;

export const Description = styled.p`
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  line-height: 40px;
  margin: 20px auto 40px auto;;
  width: 60%;
  color: ${colors.whiteColor};
  font-family: 'Century Gothic', sans-serif;


  @media screen and (max-width: 900px) {
    width: 90%;
    flex-direction: column;
  }
`;

export const Indent = styled.div`
  max-width: 600px;
  padding: 40px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.black};
  border-radius: 7px;
  box-shadow: 0 0px 5px ${colors.black};

  .form {
    width: 100%;
    max-width: 551.2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${colors.black};
  }

  @media screen and (max-width: 900px) {

    .form {
      width: 90%;
    }
  }
`;

export const Input = styled.input`
  border: none;
  height: 30px;
  color: ${colors.secondaryColor};
  width: 100%;
  margin: 0 0 20px 0;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border-radius: 7px;
  font-weight: 700;
  font-size: 24px;
  background: ${colors.black};
  font-family: 'Century Gothic', sans-serif;

  ::placeholder {
    color: ${colors.secondaryColor};
  }
  
  :focus{
    outline: 0;
  }

  @media screen and (max-width: 900px) {
  }
`;

export const InputArea = styled.textarea`
  border: none;
  height: 100px;
  color: ${colors.secondaryColor};
  width: 100%;
  padding: 10px;
  border-radius: 7px;
  font-weight: 700;
  font-size: 24px;
  background: ${colors.black};
  font-family: 'Century Gothic', sans-serif;

  ::placeholder {
    color: ${colors.secondaryColor};
  }

  :focus{
    outline: 0;
  }

  @media screen and (max-width: 900px) {
  }
`;

export const Button = styled.button`
  border: 1px solid ${colors.secondaryColor};
  height: 50px;
  background: ${colors.black};
  width: 230px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0 0;
  color: ${colors.secondaryColor};
  font-size: 23px;
  font-weight: 400;
  cursor: pointer;
  position: relative;

  @media screen and (max-width: 900px) {
  }
`;

export const TextButton = styled.p`
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0;

  @media screen and (max-width: 900px) {
  }
`;

export const Loading = styled.div`
  @media screen and (max-width: 900px) {
  }
`;

export const AuxLoading = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 900px) {
  }
`;

export const Modal = styled.div`
  position: absolute;
  right: 40%;
  top: 50%;
  background-color: ${colors.secondaryColor};
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 0 0px 5px ${colors.black};

  @media screen and (max-width: 900px) {
    width: 100%;
    right: 0%;
    top: 70%;
  }
`; 

export const TextModal = styled.p`
  font-size: 20px;
  font-weight: 500;
  @media screen and (max-width: 900px) {
  }
`;

export const ButtonModal = styled.button`
  border: none;
  height: 30px;
  background: ${colors.black};
  width: 70px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0 auto;
  color: ${colors.secondaryColor};
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  position: relative;

  @media screen and (max-width: 900px) {
  }
`;
