import React from "react";
import { Container, Block, Title, Description, Button, Span, ButtonMobile } from "./styles.js";
import OqueFazemosPicture from "../../assets/oquefazemos.jpeg";



const Ads = () => {
  return (
    <Container id="ads">
      <Block>
        <Title>
          Google <span style={{color : 'green'}}>A</span ><span style={{color : 'yellow'}}>d</span><span style={{color : '#00a8ff'}}>s</span>
        </Title>
        <Description>
          Impacte seu cliente < br />
          rapidamente. < br />
          Posicione seus anúncios< br />
          nos primeiros lugares e< br />
         
        </Description>
        <Button href="https://api.whatsapp.com/send?phone=5511972236923"> aumente suas vendas< br />
          <Span>agora mesmo.</Span>
        </Button>
        </Block>
        <ButtonMobile href="https://api.whatsapp.com/send?phone=5511972236923"> aumente suas vendas< br />
          <Span>agora mesmo.</Span>
        </ButtonMobile>
    </Container>
  );
};

export default Ads;
